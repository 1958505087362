<template>
  <v-dialog
    v-model="dialog"
    scrollable
    width="80%"
    persistent
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <v-card class="shipment-dialog">
      <v-card-title>
        <span class="text-h6">Montagem da Carga</span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <shipment-resume
          :shipment="form.shipment"
        />

        <v-form ref="formRef">
          <v-divider
            class="py-4"
          />

          <v-row
            justify="center"
          >
            <v-col
              cols="6"
              sm="5"
              class="py-0"
            >
              <v-select
                v-model="form.shippingId"
                label="Expedir Pedido"
                :items="shippingOptions"
                outlined
                x-large
              />
            </v-col>
            <v-col
              cols="8"
              sm="5"
              class="py-0"
            >
              <v-text-field
                ref="codeRef"
                v-model="form.code"
                label="Produto"
                placeholder="Código Pallet, Código Barras, SSCC"
                persistent-placeholder
                icon="access_time"
                hint="Aperte 'enter' para confirmar"
                outlined
                x-large
                @keyup.enter="addItem"
              />
            </v-col>
          </v-row>

          <div class="d-flex">
            <span class="flex-grow-1 text-h5 mt-4 black--text">Produtos</span>
          </div>
          <v-divider class="mb-4" />
          <v-data-table
            :headers="headers"
            :items="products"
            group-by="idx"
            hide-default-footer
            disable-pagination
            disable-sort
            disable-filtering
            show-expand
            single-expand
          >
            <template #[`group.header`]="{ items: [{ shippingId: group }], headers, items }">
              <template v-if="group">
                <td
                  v-for="h in headers"
                  :key="h.value"
                  :class="h.align ? `text-${h.align}` : 'text-start'"
                >
                  <template v-if="h.value === 'item.code'">
                    {{ shippings[group].code }}
                  </template>
                  <template v-else-if="h.value === 'item.description'">
                    <b>{{ shippings[group].customer.name }}</b>
                  </template>
                  <template v-else-if="h.value === 'grossWeight'">
                    <v-chip
                      small
                      dark
                    >
                      {{ formatNumber(shippings[group].grossWeight) }}
                      <v-avatar
                        right
                        color="primary"
                      >
                        Kg
                      </v-avatar>
                    </v-chip>
                  </template>
                  <template v-else-if="h.value === 'netWeight'">
                    <v-chip
                      small
                      dark
                    >
                      {{ formatNumber(shippings[group].netWeight) }}
                      <v-avatar
                        right
                        color="primary"
                      >
                        Kg
                      </v-avatar>
                    </v-chip>
                  </template>
                  <template v-else-if="h.value === 'packings.length'">
                    <v-chip
                      color="teal"
                      small
                      text-color="white"
                    >
                      <v-avatar
                        left
                        class="teal darken-4"
                      >
                        {{ formatNumber(shippings[group].pallets.length) }}
                      </v-avatar>
                      Pallets
                    </v-chip>
                    <v-chip
                      color="blue"
                      small
                      text-color="white"
                    >
                      <v-avatar
                        left
                        class="blue darken-4"
                      >
                        {{ formatNumber(shippings[group].packingsQuantity) }}
                      </v-avatar>
                      Caixas
                    </v-chip>
                  </template>
                  <template v-else-if="h.value === 'actions'">
                    <v-menu
                      offset-y
                      transition="scale-transition"
                    >
                      <template #activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          color="teal"
                          icon
                          small
                          v-on.stop="on"
                        >
                          <v-icon
                            dark
                            dense
                          >
                            pallet
                          </v-icon>
                        </v-btn>
                      </template>

                      <v-list class="dropdown-list">
                        <v-list-item
                          v-for="(pallet, index) in shippings[group].pallets"
                          :key="index"
                          @click="showPallet(shippings[group], items, pallet.id)"
                        >
                          <v-list-item-icon>
                            <v-icon>pallet</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>{{ pallet.description }}</v-list-item-title>
                            <v-list-item-subtitle>{{ pallet.barcode }}</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn
                              icon
                              @click.stop="unlinkPallet(pallet)"
                            >
                              <v-icon color="red">
                                remove
                              </v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>

                        <v-divider
                          v-if="shippings[group].pallets.length > 0 && shippings[group].hasPendingPallet"
                        />

                        <v-list-item
                          v-if="shippings[group].hasPendingPallet || items.some(product => !product.completed) || !onlyShipped.value"
                          @click="showPallet(shippings[group], items)"
                        >
                          <v-list-item-icon>
                            <v-icon>add</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Novo Pallet</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </td>
              </template>
            </template>
            <template #expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-data-table
                  :items="item.packings"
                  :headers="packingHeaders"
                  hide-default-footer
                  disable-pagination
                  disable-sort
                  disable-filtering
                  class="my-4"
                >
                  <template #[`item.quantity`]="{ value, item }">
                    {{ formatNumber(value) }} {{ item.measurement }}
                  </template>
                  <template #[`item.grossWeight`]="{ value }">
                    {{ formatNumber(value) + ' Kg' }}
                  </template>
                  <template #[`item.tareWeight`]="{ value }">
                    {{ formatNumber(value) + ' Kg' }}
                  </template>
                  <template #[`item.netWeight`]="{ value }">
                    {{ formatNumber(value) + ' Kg' }}
                  </template>
                  <template #[`item.date`]="{ value }">
                    {{ formatDate(value, 'DD/MM/YYYY') }}
                  </template>
                </v-data-table>
              </td>
            </template>
            <template #[`item.quantity`]="{ value, item }">
              {{ formatNumber(value) }} {{ item.item.measurement }}
              <span
                v-if="item.item.grossWeight"
                class="grey--text text--darken-1 ml-1"
              >
                ({{ formatNumber(value * item.item.grossWeight) }} KG)
              </span>
            </template>
            <template #[`item.grossWeight`]="{ value }">
              {{ formatNumber(value) + ' Kg' }}
            </template>
            <template #[`item.netWeight`]="{ value }">
              {{ formatNumber(value) + ' Kg' }}
            </template>
            <template #[`item.packings.length`]="{ value }">
              {{ formatNumber(value) }} Caixas
            </template>
            <template #[`item.actions`]="{ item }">
              <v-tooltip
                v-if="!item.completed || !onlyShipped.value"
                bottom
              >
                <template #activator="{ on }">
                  <v-btn
                    color="primary"
                    icon
                    small
                    v-on="on"
                    @click="addPacking(item)"
                  >
                    <v-icon>scale</v-icon>
                  </v-btn>
                </template>
                Nova Embalagem/Pesagem
              </v-tooltip>
            </template>
          </v-data-table>
        </v-form>
      </v-card-text>
      <v-card-actions class="flex-column">
        <div class="full-width d-flex flex-row">
          <v-btn
            color="secondary"
            outlined
            @click="close()"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="isSaveVisible"
            color="primary"
            outlined
            @click="save()"
          >
            Salvar
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>

    <PackingDialog
      ref="packingDialogRef"
      :only-shipped="onlyShipped"
      @close="onPackingClose"
    />

    <PalletDialog
      ref="palletDialogRef"
      :loaded-at="form.shipment?.operationTime"
      :load-number="form.shipment?.code"
      :only-shipped="onlyShipped"
      @close="onPackingClose"
    />
  </v-dialog>
</template>

<style lang="scss">
.shipment-dialog {
  .v-chip.v-size--small .v-avatar {
    height: 14px !important;
    min-height: 14px !important;
    min-width: 14px !important;
    width: unset !important;
    border-radius: 6px !important;
    padding: 3px;
  }
}

.dropdown-list {
  max-height: calc(100vh - 20px);
  overflow-y: auto;
}
</style>

<script setup>
import { ref, reactive, computed, watch, nextTick } from 'vue'
import { useUtils } from '@/Support/Composables/utils.js'
import { useDialogHistory } from '@/Support/Composables/dialogHistory.js'
import axios from '@/Support/Resources/axios-instance.js'
import api from '@/Domains/Shipment/Api/Shipment.js'
import moment from 'moment'

import ShipmentResume from '@/Domains/Shipment/Shipment/Components/ShipmentResume.vue'
import PackingDialog from '@/Domains/Shipment/StorageControl/Components/PackingDialog.vue'
import PalletDialog from '@/Domains/Shipment/StorageControl/Components/PalletDialog.vue'

import { useShipment } from '@/Domains/Shipment/Shipment/Composables/Shipment.js'

const { progressBar, notify, confirm } = useUtils()

const formatNumber = (value) => !value ? 0 : new Intl.NumberFormat('pt-BR').format(value)
const formatDate = (value, format) => !value ? '-' : moment(value).format(format)

// eslint-disable-next-line no-undef
const emit = defineEmits(['save', 'close'])

const headers = [
  { text: 'Código', value: 'item.code', width: 50 },
  { text: 'Produto', value: 'item.description' },
  { text: 'Quantidade', value: 'quantity' },
  { text: 'Peso Bruto', value: 'grossWeight', mask: '#,##0.00' },
  { text: 'Peso Líquido', value: 'netWeight', mask: '#,##0.00' },
  { text: '', value: 'packings.length' },
  { text: '', value: 'actions', width: 60 },
]

const packingHeaders = [
  { text: 'Lote', value: 'lotNumber' },
  { text: 'Quantidade', value: 'quantity' },
  { text: 'Peso Bruto', value: 'grossWeight', mask: '#,##0.00' },
  { text: 'Peso Tara', value: 'tareWeight', mask: '#,##0.00' },
  { text: 'Peso Líquido', value: 'netWeight', mask: '#,##0.00' },
  { text: 'Data', value: 'date' },
  { text: 'Código de Barras', value: 'barcode' },
  { text: 'Pallet', value: 'palletCode' },
]

const form = reactive({
  id: null,
  shipment: {
    shippings: [],
  },
  shippingId: null,
  code: '',
})

const { onlyShipped, shippingOptions, findPacking } = useShipment(form)

const products = computed(() => {
  if (!form.shipment?.shippings) {
    return []
  }

  return form.shipment.shippings.flatMap((shipping) => {
    return shipping.products
      .filter((product) => product.shipped > 0 || !onlyShipped.value)
      .map((product) => {
        return {
          idx: shipping.idx,
          shippingId: shipping.id,
          ...product,
          grossWeight: product.packings.reduce((acc, cur) => acc + cur.grossWeight, 0),
          netWeight: product.packings.reduce((acc, cur) => acc + cur.netWeight, 0),
          completed: product.packings.reduce((acc, cur) => acc + cur.quantity, 0) >= product.realQuantity,
        }
      })
  })
})

const shippings = computed(() => {
  if (!form.shipment?.shippings) {
    return {}
  }

  return form.shipment.shippings.reduce((acc, shipping) => {
    acc[shipping.id] = {
      ...shipping,
      ...shipping.products.reduce((acc, cur) => {
        acc.packingsQuantity += cur.packings.length
        acc.grossWeight += cur.packings.reduce((acc, cur) => acc + cur.grossWeight, 0)
        acc.netWeight += cur.packings.reduce((acc, cur) => acc + cur.netWeight, 0)
        return acc
      }, {
        packingsQuantity: 0,
        grossWeight: shipping.pallets.reduce((acc, cur) => acc + cur.palletTare + cur.stretchTare, 0),
        netWeight: 0,
      }),
      hasPendingPallet: shipping.products.some((product) => product.packings.some((packing) => !packing.palletId)),
    }

    return acc
  }, {})
})

const dialog = ref(false)

useDialogHistory(dialog)

const formRef = ref()

const show = (id) => {
  dialog.value = true
  formRef.value?.reset()
  form.id = id
  form.shipment = {
    shippings: [],
  }
  form.shippingId = null
  form.code = ''
  load()
}

watch(dialog, (isOpen) => {
  if (!isOpen) { emit('close') }
})

const close = () => {
  dialog.value = false
}

const load = async () => {
  try {
    progressBar?.loading()

    form.shipment = await api.show(form.id)

    applyPendingPackingStocks()

  } catch (e) {
    console.error(e)
    const message = e?.response?.data?.message || 'Erro ao carregar'
    notify.error(message, 'Atenção')
  } finally {
    progressBar?.hide()
  }
}

const applyPendingPackingStocks = () => {
  form.shipment.shippings.forEach((shipping) => {
    shipping.products.forEach((product) => {

      const currentStocks = product.stocks.reduce((acc, cur) => ({ ...acc, [cur.lotNumber]: cur.quantity }), {})

      const currentPackings = product.packings.reduce((acc, cur) => {
        if (!acc[cur.lotNumber]) {
          acc[cur.lotNumber] = 0
        }

        acc[cur.lotNumber] += cur.quantity

        return acc
      }, {})

      for (const lotNumber in currentPackings) {
        const stockQuantity = currentStocks[lotNumber] || 0
        const remainingQuantity = currentPackings[lotNumber] - stockQuantity

        if (remainingQuantity > 0) {
          product.remaining.lots.push({
            numero: lotNumber,
            quantidade: remainingQuantity
          })

          product.remaining.quantityLot += remainingQuantity / product.item.conversionFactor
          product.remaining.shipped += remainingQuantity / product.item.conversionFactor
        }
      }
    })
  })
}

const isSaveVisible = computed(() => products.value.some((product) => product.remaining.shipped > 0))

const save = async () => {
  if (!await formRef.value?.validate()) {
    return
  }

  try {
    progressBar?.saving()

    const { shipment } = form

    const payload = {
      entregas: shipment.shippings.map(shipment => ({
        id: shipment.id,
        pallets: shipment.pendingPalletCodes,
        produtos: shipment.products
          .filter(p => p.remaining.shipped > 0 || p.remaining.packingCodes.length > 0)
          .map(p => ({
            id: p.id,
            id_item: p.item.itemId,
            quantidade: p.remaining.shipped * p.item.conversionFactor,
            lotes: p.remaining.lots,
            embalagens: p.remaining.packingCodes,
          })),
      })),
    }

    await api.update(shipment.id, payload)

  } catch (e) {
    console.error(e)
    const message = e?.response?.data?.message || 'Erro ao salvar'
    notify.error(message, 'Atenção')
  } finally {
    progressBar?.hide()
    load()
  }
}

const packingDialogRef = ref()
const palletDialogRef = ref()

const addPacking = (product) => {
  packingDialogRef.value.show({
    shippingId: product.shippingId,
    pendingProducts: [
      {
        ...product.item,
        shippingItemId: product.id,
        quantity: product.quantity,
        realQuantity: product.realQuantity,
        stocks: product.stocks,
      }
    ]
  })
}

const showPallet = (shipping, groupedProducts, palletId = null) => {
  palletDialogRef.value.show({
    id: palletId,
    shippingId: shipping.id,
    pendingPackings: shipping.products
      .flatMap((product) => product.packings.filter((packing) => !packing.palletId)),
    pendingProducts: groupedProducts
      .filter((product) => !product.completed || !onlyShipped.value)
      .map((product) => ({
        ...product.item,
        shippingItemId: product.id,
        quantity: product.quantity,
        realQuantity: product.realQuantity,
        stocks: product.stocks,
      })),
  });
}

const unlinkPallet = async (pallet) => {
  if (!(await confirm(`Desvincular pallet: ${pallet.description}?`, `Esta ação não pode ser desfeita.`, { color: 'red' }))) {
    return
  }

  try {
    progressBar.loading()

    await axios.put(`/shipping/unlink-pallet`, { id: pallet.id });

    notify.success('Pallet desvinculado com sucesso')
  } catch (error) {
    console.warn(error)
    const message = error?.response?.data?.message || 'Erro ao desvincular o pallet'
    notify.error(message, 'Atenção')
  } finally {
    progressBar.hide()
    load()
  }
}

const codeRef = ref()

const addItem = async () => {
  if (!form.shippingId) {
    return notify.warning('Selecione o pedido', 'Atenção')
  }

  const code = form.code?.trim()
  if (code) {
    // Verifica se o código é de um pallet ou caixa previamente cadastrado
    const result = await findPacking(code, form.shippingId)

    if (result === 'SUCCESS') {
      save()
    }
  }
  codeRef.value?.reset()
  codeRef.value?.focus()
}

const onPackingClose = async () => {
  await load()

  await nextTick()

  if (isSaveVisible.value) {
    await save()
  }
}

// eslint-disable-next-line no-undef
defineExpose({ show, close })
</script>
