<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <h2 class="menu-header white--text mt-0 mb-2">
          Auditoria
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-card
      color="transparent"
      dark
    >
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="pt-0"
          >
            <v-date-range-picker
              v-model="filters.range"
              label="Data Pedido"
              dark
              @change="load"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md
            class="pt-0"
          >
            <v-select
              v-model="filters.event"
              :items="[
                { value: 'CREATED', text: 'CADASTRADO' },
                { value: 'UPDATED', text: 'ATUALIZADO' },
                { value: 'DELETED', text: 'APAGADO' },
              ]"
              dark
              clearable
              filled
              hide-details
              label="Evento"
              prepend-inner-icon="rule"
              multiple
              small-chips
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md
            class="pt-0"
          >
            <v-select
              v-model="filters.module"
              :items="modules"
              dark
              clearable
              filled
              hide-details
              label="Módulo"
              prepend-inner-icon="widgets"
              small-chips
              @change="load"
            />
          </v-col>
          <v-col
            cols="12"
            sm
            class="pt-0"
          >
            <v-text-field
              v-model="filters.search"
              label="Pesquisar"
              prepend-inner-icon="search"
              dark
              filled
              hide-details
              clearable
              @change="load"
            />
          </v-col>
        </v-row>
      </v-card-title>

      <data-table
        :headers="headers"
        :items="filteredItems"
        dark
        dense
        show-expand
        single-expand
        item-key="_id"
        @item-expanded="onDetails"
      >
        <template #[`item.module`]="{ value }">
          <v-chip
            x-small
          >
            {{ getModuleName(value) }}
          </v-chip>
        </template>
        <template #[`item.event`]="{ value }">
          <v-chip
            x-small
            :color="getEventColor(value)"
          >
            {{ getEventName(value) }}
          </v-chip>
        </template>

        <template #expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
            class="pa-0"
          >
            <v-row no-gutters>
              <v-col
                v-if="!item.comparison"
                cols="12"
              >
                Carregando...
              </v-col>
              <v-col
                v-else-if="item.event !== 'DELETED'"
                cols="12"
              >
                <audit-item
                  :item-key="item._id"
                  :value="item.comparison"
                />
              </v-col>
            </v-row>
          </td>
        </template>
      </data-table>
    </v-card>
  </v-container>
</template>

<script>
import VDateRangePicker from '@/Support/Components/VDateRangePicker.vue';
import AuditItem from '@/Domains/Audit/Components/AuditItem.vue';

import moment from 'moment-timezone';
import isArray from 'lodash/fp/isArray';
import isEmpty from 'lodash/fp/isEmpty';
import isObject from 'lodash/fp/isObject';

export default {
  components: {
    VDateRangePicker,
    AuditItem,
  },

  data() {
    return {
      filters: {
        range: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        event: [],
        module: '',
        search: ''
      },

      headers: [
        { text: 'Módulo', value: 'module' },
        { text: 'Descrição', value: 'description' },
        { text: 'Evento', value: 'event' },
        { text: 'Usuário', value: 'userName' },
        { text: 'Data', value: 'createdAt', formatter: value => this.formatDate(value, 'DD/MM/YYYY HH:mm:ss') },
      ],

      items: [],

      modules: [
        { value: 'ITINERARIO', text: 'ITINERÁRIO' },
        { value: 'COLETA', text: 'COLETA' },
        { value: 'VISITA', text: 'VISITA' },
        { value: 'DESCARGA', text: 'CARGA/DESCARGA' },
        { value: 'DESCARGA_ANALISE', text: 'ÁNALISES DESCARGA' },
        { value: 'TABELA_PRECO', text: 'TABELA LEITE' },
        { value: 'VALOR_LEITE', text: 'PREÇO LEITE' },
        { value: 'CONFIGURACAO_LATICINIO', text: 'CONFIG. LATICÍNIO' },
        { value: 'CONFIGURACOES_LATICINIO', text: 'CONFIG. APP' },
        { value: 'CONFIGURACOES_PLATAFORMA', text: 'CONFIG. PLATAFORMA' },
        { value: 'CONTA', text: 'CONTA FINANC.' },
        { value: 'PRODUTOR', text: 'CAD. PRODUTOR' },
        { value: 'USUARIO', text: 'CAD. USUÁRIO' },
        { value: 'FORNECEDOR', text: 'CAD. FORNECEDOR' },
        { value: 'VETERINARIO', text: 'CAD. VETERINARIO' },
        { value: 'CLIENTE', text: 'CAD. CLIENTE' },
        { value: 'COOPERATIVA', text: 'CAD. COOPERATIVA' },
        { value: 'PESSOA', text: 'CAD. PESSOA' },
        { value: 'LATICINIO', text: 'CAD. LATICÍNIO' },
        { value: 'PRODUTO', text: 'PRODUTO' },
        { value: 'ARMAZENAMENTO_PALLET', text: 'PALLET' },
        { value: 'ARMAZENAMENTO_EMBALAGEM', text: 'EMBALAGEM' },
      ]
    }
  },

  computed: {
    /**
     * Exibe as ordens com base nos filtros selecionados em tela
     */
    filteredItems() {
      if (isEmpty(this.filters.event)) {
        return this.items;
      }

      return this.items.filter(item => {
        const hasEvent = isEmpty(this.filters.event) || this.filters.event.includes(item.event);

        return hasEvent;
      });
    },
  },

  created() {
    this.load()
  },

  methods: {
    async load() {
      try {
        this.$root.$progressBar.loading();

        const [startDate, endDate] = this.filters.range;

        const { data } = await this.$axios.get('/audit', { params: {
          start_date: startDate,
          end_date: endDate,
          module: this.filters.module,
          _q: this.filters.search ? this.filters.search.trim() : '',
        } });

        this.items = data.sort((a, b) => b.createdAt.localeCompare(a.createdAt));

      } catch (error) {
        this.$snotify.error('Erro ao carregar os logs', 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async onDetails({ item, value }) {
      try {

        // Não faz nada quando fechar
        if (!value) {
          return;
        }

        // Verifica se os dados já foram carregados do servidor
        if (item.comparison) {
          return;
        }

        if (item.event === 'DELETED') {
          const index = this.items.findIndex(log => log._id === item._id);
          this.items.splice(index, 1, { comparison: {}, ...this.items[index] });
          return;
        }

        const { data } = await this.$axios.get(`/audit/${item._id}`);

        const comparison = Object.assign({}, data.current);

        this.prepareObject(comparison);
        this.handleObject(comparison, data.previous);

        const index = this.items.findIndex(log => log._id === item._id);
        this.items.splice(index, 1, { comparison, ...this.items[index] });

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os detalhes!", "Atenção");

        console.error(err);
      }
    },

    prepareObject(object) {
      Object.keys(object)
        .forEach(key => {
          if (isObject(object[key])) {
            this.prepareObject(object[key])

            if (isArray(object[key])) {
              object[key] = object[key].filter(o => o);

              if (isEmpty(object[key])) {
                delete object[key]
              }
            }
          } else if (object[key] != null) {
            object[key] = {
              current: object[key],
              previous: '-'
            }
          } else {
            delete object[key]
          }
        });
    },

    handleObject(comparison, previous) {
      Object.keys(previous)
        .forEach(key => {
          if (key in comparison) {
            if (isObject(previous[key])) {
              this.handleObject(comparison[key], previous[key]);

              if (isArray(comparison[key])) {
                comparison[key] = comparison[key].filter(o => o);
              }

              if (isEmpty(comparison[key])) {
                delete comparison[key];
              }
            } else if ((!comparison[key].current && !previous[key])) {
              delete comparison[key];
            } else {
              comparison[key] = {
                current: this.parseField(comparison[key].current),
                previous: this.parseField(previous[key] || '-'),
              }
            }
          }
          else if (isArray(previous[key])) {
            previous[key] = previous[key].filter(o => o);

            comparison[key] = [];

            for (let item of previous[key]) {
              if (isObject(item)) {
                for (const property in item) {
                  item[property] = {
                    current: '-',
                    previous: this.parseField(item[property])
                  }
                }
              } else {
                item = {
                  current: '-',
                  previous: this.parseField(item)
                }
              }

              comparison[key].push(item);
            }
          }
          else if (previous[key] != null) {
            comparison[key] = {
              current: '-',
              previous: this.parseField(previous[key])
            }
          }
        });
    },

    getModuleName(value) {
      const moduleNames = {
        'ITINERARIO': 'ITINERÁRIO',
        'VALOR_LEITE': 'PREÇO LEITE',
        'TABELA_PRECO': 'TABELA LEITE',
        'DESCARGA': 'CARGA/DESCARGA',
        'DESCARGA_ANALISE': 'ÁNALISES DESCARGA',
        'CONFIGURACAO_LATICINIO': 'CONFIG. LATICÍNIO',
        'CONFIGURACOES_LATICINIO': 'CONFIG. APP',
        'CONFIGURACOES_PLATAFORMA': 'CONFIG. PLATAFORMA',
        'ARMAZENAMENTO_PALLET': 'PALLET',
        'ARMAZENAMENTO_EMBALAGEM': 'EMBALAGEM',
      }
      return moduleNames[value] || value
    },

    getEventName(value) {
      const eventNames = {
        'CREATED': 'CADASTRADO',
        'UPDATED': 'ATUALIZADO',
        'DELETED': 'APAGADO',
      }
      return eventNames[value]
    },

    getEventColor(value) {
      const eventColors = {
        'CREATED': 'green darken-1',
        'UPDATED': 'orange darken-1',
        'DELETED': 'red darken-1',
      }
      return eventColors[value]
    },

    parseField(field) {
      // Check if field is datetime with regex [ex: 2024-05-06T12:23:21 or 	2024-05-06 12:23:21 or 2024-07-05T16:34:32.410000Z]
      if (/^\d{4}-\d{2}-\d{2}([T ]{1}?)\d{2}:\d{2}:\d{2}(.*?)$/.test(field)) {
        return moment(field).format('DD/MM/YYYY HH:mm:ss')
      }
      if (/^\d{4}-\d{2}-\d{2}$/.test(field)) {
        return moment(field).format('DD/MM/YYYY')
      }
      return field
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
  }
}
</script>
